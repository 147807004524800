import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { SnippetsOutlined, FormOutlined } from '@ant-design/icons';
import { usePatientStore, useFilterStore } from '../../store';
import PsoForm from './PsoForm';
import ExtendedNotes from './ExtendedNotes';

const PatientForm = ({ messageApi }) => {
  const {
    selectedPatient,
    fetchExtendedNote,
    patientExtendedNote,
    updatePsoForm,
    updateExtendedNote,
    isSavingPatient,
    isSavingPatientSuccess,
    isSavingPatientError,
    isSavingExtendedNoteSuccess,
    isSavingExtendedNoteError,
    isSavingExtendedNote,
    clearExtendedNote,
    setPsoFormUpdated,
    clearPsoForm,
  } = usePatientStore((state) => ({
    patientExtendedNote: state.patientExtendedNote,
    selectedPatient: state.selectedPatient,
    fetchExtendedNote: state.fetchExtendedNote,
    updatePsoForm: state.updatePsoForm,
    isSavingPatient: state.isSavingPatient,
    isSavingPatientSuccess: state.isSavingPatientSuccess,
    isSavingPatientError: state.isSavingPatientError,
    updateExtendedNote: state.updateExtendedNote,
    isSavingExtendedNoteSuccess: state.isSavingExtendedNoteSuccess,
    isSavingExtendedNoteError: state.isSavingExtendedNoteError,
    isSavingExtendedNote: state.isSavingExtendedNote,
    clearExtendedNote: state.clearExtendedNote,
    setPsoFormUpdated: state.setPsoFormUpdated,
    clearPsoForm: state.clearPsoForm,
  }));

  const { sitecodeList } = useFilterStore((state) => ({
    sitecodeList: state.sitecodeList,
  }));

  const [formUpdated, setFormUpdated] = useState(false);

  useEffect(() => {
    if (selectedPatient && selectedPatient.caseID) {
      fetchExtendedNote(selectedPatient.caseID);
    }
  }, [selectedPatient, fetchExtendedNote]);

  // when modal closed and form unmounted, if form is updated, set psoFormUpdated to true, notify Filter to fetch patients
  useEffect(() => {
    return () => {
      if (formUpdated) {
        setPsoFormUpdated(true);
      }
    };
  }, [formUpdated, setPsoFormUpdated]);

  return (
    <Tabs
      type="card"
      size="large"
      items={[
        {
          key: 1,
          label: 'Patient PSO form',
          children: (
            <PsoForm
              messageApi={messageApi}
              sitecodeList={sitecodeList}
              selectedPatient={selectedPatient}
              updatePsoForm={updatePsoForm}
              clearPsoForm={clearPsoForm}
              setFormUpdated={setFormUpdated}
              isSavingPatient={isSavingPatient}
              isSavingPatientSuccess={isSavingPatientSuccess}
              isSavingPatientError={isSavingPatientError}
            />
          ),
          icon: <FormOutlined />,
        },
        {
          key: 2,
          label: 'Extended Notes',
          children: (
            <ExtendedNotes
              messageApi={messageApi}
              clearExtendedNote={clearExtendedNote}
              selectedPatient={selectedPatient}
              patientExtendedNote={patientExtendedNote}
              updateExtendedNote={updateExtendedNote}
              isSavingExtendedNoteSuccess={isSavingExtendedNoteSuccess}
              isSavingExtendedNoteError={isSavingExtendedNoteError}
              isSavingExtendedNote={isSavingExtendedNote}
            />
          ),
          icon: <SnippetsOutlined />,
        },
      ]}
    />
  );
};

export default PatientForm;
