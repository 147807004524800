import axios from 'axios';

export const getSitecodeList = async () => {
  try {
    let res = await axios.get(
      'https://med-discrete-data-uat.azurewebsites.net/api/getSitecodes?code=9byg-vFzC3JaPhvlh8oYMA8FrV7k4ZhDg1aoU7zS5J-TAzFuX2sfqg=='
    );
    return res.data;
  } catch (err) {
    console.error('err', err);
  }
};
