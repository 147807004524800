import axios from 'axios';

// TODO: Add prod url
export const getFormFieldsType = async () => {
  try {
    const url = `https://med-discrete-data-uat.azurewebsites.net/api/getFieldsType?code=BKkWwWRRLxuIqwwfNW_gMeKWd9lDToITAuB66yhne4L5AzFu7db9Ow==`;
    const res = await axios.get(url);
    return res.data;
  } catch (err) {
    console.log('err', err);
  }
};

export const getFormNotesTemplate = async (sitecode) => {
  //get form template based on client type
  let result = {};
  const url = `https://med-discrete-data-uat.azurewebsites.net/api/getFormTemplateBySitecode?code=IZHpYkUXzqTOmquCxQQq_0rCYIGaxyJTrQpJAsyMm6V2AzFuQIaIDg==&sitecode=${sitecode}`;

  try {
    let res = await axios.get(url);
    if (res) result = res.data;
  } catch (err) {
    console.log('err', err);
  }
  return result;
};
