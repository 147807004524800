import { MANDATORY_STRING, OPTIONAL_STRING } from '../constants';

export const parseFieldSetup = (string) => {
  let parsedString = '';
  if (typeof string === 'string') {
    if (string.includes(MANDATORY_STRING) || string.includes(OPTIONAL_STRING)) {
      parsedString = string.split('/')?.[1] || '';
    } else {
      parsedString = string;
    }
  }
  return parsedString;
};
