import React, { useState } from 'react';
import {
  Table,
  Space,
  Button,
  Tooltip,
  Dropdown,
  Tag,
  Modal,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Radio,
  Typography,
} from 'antd';
import {
  FormOutlined,
  LinkOutlined,
  DownOutlined,
  PhoneOutlined,
  VideoCameraOutlined,
  PlusOutlined,
  DeploymentUnitOutlined,
  LineOutlined,
} from '@ant-design/icons';
import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import { usePatientStore, useModalStore, useFilterStore } from '../store';
import { MODAL_TYPES } from '../constants';

import { parseFieldSetup } from '../utils/stringUtils';
import { searchPatients, updateLinkCallByCaseID } from '../utils/fetchPatients';

dayjs.extend(utc);
// dayjs.extend(timezone);
const { Text } = Typography;

const PatientTable = ({ userInfo }) => {
  const {
    activeCalls,
    validPatients,
    isFetchingPatients,
    onSelectPatient,
    selectedPatient,
  } = usePatientStore((state) => ({
    activeCalls: state.activeCalls,
    isFetchingPatients: state.isFetchingPatients,
    validPatients: state.validPatients,
    onSelectPatient: state.onSelectPatient,
    selectedPatient: state.selectedPatient,
  }));

  console.log('activeCalls', activeCalls);
  const { filters } = useFilterStore((state) => ({
    filters: state.filters,
  }));

  const { updateModalStatus } = useModalStore((state) => ({
    updateModalStatus: state.updateModalStatus,
  }));

  const [, setSelectedRecord] = useState(null);
  const [visible, setVisible] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedCaseIDs, setSelectedCaseIDs] = useState(new Set());
  const [searchData, setSearchData] = useState({
    patientFirstName: '',
    patientLastName: '',
    dateFrom: dayjs().subtract(48, 'hour'),
    dateTo: dayjs(),
  });

  const showModal = (record) => {
    onSelectPatient(record);
    setSelectedRecord(record);
    setVisible(true);
  };

  const handleOk = async () => {
    if (selectedPatient && selectedPatient.caseID) {
      const selectedResults = searchResults.filter((result) =>
        selectedCaseIDs.has(result.caseID)
      );

      const data = {
        caseIDOriginal: selectedPatient.caseID,
        caseIDSecs: selectedResults.map((result) => result.caseID),
        linkedBy: userInfo?.username || userInfo?.name || 'Unknown',
      };

      try {
        await updateLinkCallByCaseID(data);
      } catch (error) {
        console.error('Error linking calls:', error);
      }
    }

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSearch = async () => {
    try {
      const results = await searchPatients(searchData);
      const updatedResults = results.map((result) => ({
        ...result,
        selected: selectedCaseIDs.has(result.caseID),
      }));
      setSearchResults(updatedResults);
    } catch (error) {
      console.error('Error fetching patients:', error);
    }
  };

  const handleSelect = (caseID) => {
    console.log('called');
    setSelectedCaseIDs((prevSelectedCaseIDs) => {
      const updatedSet = new Set(prevSelectedCaseIDs);
      console.log('updatedSet', updatedSet);
      if (updatedSet.has(caseID)) {
        updatedSet.delete(caseID);
      } else {
        updatedSet.add(caseID);
      }
      // Update searchResults based on the new selectedCaseIDs
      setSearchResults((prevResults) =>
        prevResults.map((result) =>
          result.caseID === caseID
            ? { ...result, selected: updatedSet.has(caseID) }
            : result
        )
      );
      return updatedSet;
    });
  };

  const isAnySelected = selectedCaseIDs.size > 0;

  const HISTORY_TABLE_COLUMNS = (showModal) => [
    {
      title: 'Patient name',
      key: 'patientFullName',
      render: (_, record) => (
        <span>{`${record?.patientFirstName ? ' ' + parseFieldSetup(record.patientFirstName) : ''}${record?.patientLastName ? ' ' + parseFieldSetup(record.patientLastName) : ''}`}</span>
      ),
      sorter: (a, b) =>
        parseFieldSetup(a.patientFirstName)
          ?.charAt(0)
          ?.localeCompare(parseFieldSetup(b.patientFirstName)?.charAt(0)) ?? -1,
      sortDirections: ['ascend', 'descend'],
      fixed: 'left',
    },
    {
      title: 'Sitecode',
      key: 'sitecode',
      sorter: (a, b) =>
        parseFieldSetup(a.sitecode)
          ?.charAt(0)
          ?.localeCompare(parseFieldSetup(b.sitecode)?.charAt(0)) ?? -1,
      sortDirections: ['ascend', 'descend'],
      render: (_, record) => <span>{parseFieldSetup(record?.sitecode)}</span>,
    },
    {
      title: 'Date Time Call connected',
      key: 'createTime',
      width: 180,
      render: (_, record) => (
        <span>
          {record?.createTime
            ? dayjs(record?.createTime).utc().format('YYYY-MM-DD HH:mm:ss')
            : ''}
        </span>
      ),
    },
    {
      title: 'FACEM / Doctor name',
      key: 'DRName',
      width: 200,
      render: (_, record) => <span>{parseFieldSetup(record?.DRName)}</span>,
    },
    {
      title: 'From number',
      key: 'PHNumber',
      width: 180,
      render: (_, record) =>
        record?.PHNumber && parseFieldSetup(record?.PHNumber) ? (
          <Text copyable>{parseFieldSetup(record.PHNumber)}</Text>
        ) : (
          <span>{parseFieldSetup(record?.PHNumber)}</span>
        ),
    },
    {
      title: 'Call Type',
      key: 'callVideoVoiceType',
      width: 130,
      render: (_, record) => {
        const validCallType = record?.callSid && record?.taskSid;
        const isVoiceCall = record.callSid !== record.taskSid;
        return (
          <span>
            {validCallType &&
              (isVoiceCall ? (
                <Tag icon={<PhoneOutlined />} color="#4eb5b0">
                  Voice Call
                </Tag>
              ) : (
                <Tag icon={<VideoCameraOutlined />} color="#6f86d6">
                  MED Website
                </Tag>
              ))}
          </span>
        );
      },
      filters: [
        {
          text: 'Voice Call',
          value: 'voice',
        },
        {
          text: 'MED Website',
          value: 'website',
        },
      ],
      onFilter: (value, record) => {
        const validCallType = record?.callSid && record?.taskSid;
        const isVoiceCall = record.callSid !== record.taskSid;
        if (validCallType && value === 'voice' && isVoiceCall) return true;
        if (validCallType && value === 'website' && !isVoiceCall) return true;
        return false;
      },
    },
    {
      title: 'Urgency',
      key: 'callType',
      width: 80,
      render: (_, record) => {
        const callType = parseFieldSetup(record?.callType);
        return (
          <span>
            {callType === 'Urgent' ? (
              <Tag color="red">Urgent</Tag>
            ) : (
              callType && <Tag color="green">{callType ?? ''}</Tag>
            )}
          </span>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => {
        return (
          <Space size="small">
            <Tooltip title="Open patient form">
              <Button
                shape="circle"
                icon={<FormOutlined />}
                onClick={() => handlePatientIconClick(record)}
                size="small"
              />
            </Tooltip>
            <Tooltip title="Link to patient">
              <Button
                shape="circle"
                icon={<LinkOutlined />}
                onClick={() => showModal(record)}
                size="small"
              />
            </Tooltip>
            <Dropdown menu={menuProps}>
              <Button size="small">
                <Space>
                  Actions
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Space>
        );
      },
      fixed: 'right',
    },
  ];

  const ACTIVE_TABLE_COLUMNS = [
    {
      title: 'Patient name',
      key: 'patientFullName',
      render: (_, record) => (
        <span>{`${record?.patientFirstName ? ' ' + parseFieldSetup(record.patientFirstName) : ''}${record?.patientLastName ? ' ' + parseFieldSetup(record.patientLastName) : ''}`}</span>
      ),
      fixed: 'left',
      width: 250,
    },
    {
      title: 'Call stage',
      key: 'callStep',
      dataIndex: 'callStep',
      width: 180,
    },
    {
      title: 'Start time',
      key: 'startTime',
      width: 200,
      render: (_, record) => (
        <span>
          {record?.startTime
            ? dayjs(record?.startTime).utc().format('YYYY-MM-DD HH:mm:ss')
            : ''}
        </span>
      ),
    },
    {
      title: 'Agent name',
      key: 'agentName',
      dataIndex: 'agentName',
      width: 200,
    },
    {
      title: 'DOB',
      key: 'dob',
      dataIndex: 'dob',
      width: 120,
    },
    {
      title: 'Multi patient',
      key: 'multiPatient',
      width: 100,
      render: (_, record) => (
        <>
          {record?.isMultiPatient === true && (
            <Tag icon={<DeploymentUnitOutlined />} color="blue">
              Yes
            </Tag>
          )}
          {record?.isMultiPatient === false && (
            <Tag icon={<LineOutlined />} color="#8c8c8c">
              No
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'Status',
      key: 'currentStatus',
      dataIndex: 'currentStatus',
      width: 130,
    },
    {
      title: 'Call Type',
      key: 'callType',
      width: 130,
      render: (_, record) => (
        <>
          {record?.callType === 'voice' && (
            <Tag icon={<PhoneOutlined />} color="#4eb5b0">
              Voice
            </Tag>
          )}
          {record?.callType === 'video' && (
            <Tag icon={<VideoCameraOutlined />} color="#6f86d6">
              Video
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'Outcome',
      key: 'outcome',
      dataIndex: 'outcome',
      // width: 200,
    },
  ];
  const handleMenuClick = (e) => {
    console.log('click', e);
  };

  const handlePatientIconClick = (record) => {
    onSelectPatient(record);
    updateModalStatus({ open: true, type: MODAL_TYPES.PATIENT_FORM });
  };

  const items = [
    {
      label: 'Action 2',
      key: '2',
      icon: <PlusOutlined />,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleValuesChange = (changedValues, allValues) => {
    setSearchData((prev) => ({
      ...prev,
      ...changedValues,
    }));
  };

  const handleDateChange = (date, dateString, type) => {
    console.log('date', type, date.format('YYYY-MM-DD'));
    setSearchData((prev) => ({
      ...prev,
      [type]: date ? date.format('YYYY-MM-DD') : '',
    }));
  };

  return filters?.callsType !== 'active' ? (
    <>
      <Table
        bordered
        size="small"
        rowKey={(record) => `${record.caseID} ${record.callSid}`}
        loading={isFetchingPatients}
        dataSource={validPatients}
        columns={HISTORY_TABLE_COLUMNS(showModal)}
        pagination={{
          pageSize: 15,
          size: 'middle',
          position: ['bottomCenter'],
        }}
        sticky={{
          offsetHeader: 0,
        }}
      />
      <Modal
        title="Link Call"
        visible={visible}
        // onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        style={{
          maxHeight: '80vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>Link the selected Patient into one call</span>
            <Button
              key="link"
              type="primary"
              disabled={!isAnySelected}
              onClick={handleOk}
            >
              Link
            </Button>
          </div>
        }
      >
        <div style={{ marginBottom: '16px' }}>
          <h2
            style={{
              borderBottom: '2px solid #ddd',
              paddingBottom: '8px',
              marginBottom: '16px',
            }}
          >
            Search
          </h2>
          <Form
            layout="vertical"
            onFinish={handleSearch}
            onValuesChange={handleValuesChange}
            initialValues={searchData}
          >
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item name="patientFirstName" label="Patient Firstname">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="patientLastName" label="Patient Lastname">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="dateFrom" label="Date From">
                  <DatePicker
                    value={
                      searchData.dateFrom ? dayjs(searchData.dateFrom) : null
                    }
                    onChange={(date, dateString) =>
                      handleDateChange(date, dateString, 'dateFrom')
                    }
                    maxDate={dayjs(new Date())}
                    mode="date"
                    showNow={false}
                    allowClear={false}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="dateTo" label="Date To">
                  <DatePicker
                    value={searchData.dateTo ? dayjs(searchData.dateTo) : null}
                    onChange={(date, dateString) =>
                      handleDateChange(date, dateString, 'dateTo')
                    }
                    maxDate={dayjs(new Date())}
                    mode="date"
                    showNow={false}
                    allowClear={false}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Col>
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <div style={{ marginBottom: '16px' }}>
          <h2 style={{ borderBottom: '2px solid #ddd', paddingBottom: '8px' }}>
            Result
          </h2>
          <Table
            dataSource={searchResults}
            columns={[
              {
                title: 'Patient Name',
                dataIndex: 'patientFullName',
                key: 'patientFullName',
                render: (_, record) => (
                  <span>{`${record?.patientFirstName ? ' ' + parseFieldSetup(record.patientFirstName) : ''}${record?.patientLastName ? ' ' + parseFieldSetup(record.patientLastName) : ''}`}</span>
                ),
              },
              {
                title: 'Date Time',
                dataIndex: 'createTime',
                key: 'createTime',
                render: (_, record) => (
                  <span>
                    {record?.createTime
                      ? dayjs(record?.createTime)
                          .utc()
                          .format('YYYY-MM-DD HH:mm:ss')
                      : ''}
                  </span>
                ),
              },
              {
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                  <Radio
                    checked={selectedCaseIDs.has(record.caseID)}
                    onChange={() => handleSelect(record.caseID)}
                  />
                ),
              },
            ]}
            pagination={{
              pageSize: 5,
              size: 'middle',
              position: ['bottomCenter'],
            }}
            sticky={{
              offsetHeader: 0,
            }}
          />
        </div>
      </Modal>
    </>
  ) : (
    <Table
      bordered
      size="small"
      loading={isFetchingPatients}
      dataSource={activeCalls && activeCalls?.length ? activeCalls : []}
      columns={ACTIVE_TABLE_COLUMNS}
      pagination={{
        pageSize: 15,
        size: 'middle',
        position: ['bottomCenter'],
      }}
      sticky={{
        offsetHeader: 0,
      }}
    />
  );
};

export default PatientTable;
