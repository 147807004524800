import React, { useEffect } from 'react';
import { message } from 'antd';
import { usePatientStore } from '../store';
import Header from './Header';
import PatientTable from './PatientTable';
import ModalService from './ModalService';

export default function PSOPanel({ userInfo }) {
  const [messageApi, messagePlaceholder] = message.useMessage();
  const {
    isFetchingPatients,
    isFetchingPatientsSuccess,
    isFetchingPatientsError,
  } = usePatientStore((state) => ({
    isFetchingPatients: state.isFetchingPatients,
    isFetchingPatientsSuccess: state.isFetchingPatientsSuccess,
    isFetchingPatientsError: state.isFetchingPatientsError,
  }));

  useEffect(() => {
    if (
      !isFetchingPatients &&
      !isFetchingPatientsSuccess &&
      isFetchingPatientsError
    ) {
      messageApi.open({
        type: 'error',
        content: 'Loading calls failed',
        duration: 3,
      });
    }
    if (
      !isFetchingPatients &&
      isFetchingPatientsSuccess &&
      !isFetchingPatientsError
    ) {
      messageApi.open({
        type: 'success',
        content: 'Calls loaded',
        duration: 3,
      });
    }
  }, [
    isFetchingPatients,
    isFetchingPatientsError,
    isFetchingPatientsSuccess,
    messageApi,
  ]);
  return (
    <>
      {messagePlaceholder}
      <Header />
      {isFetchingPatientsError ? (
        <div>
          Error: Failed to get call information, please try again or contact our
          technical support.
        </div>
      ) : (
        <>
          <ModalService messageApi={messageApi} />
          <PatientTable userInfo={userInfo} />
        </>
      )}
    </>
  );
}
