import React from 'react';
import { Modal, Popconfirm } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useModalStore } from '../store';
import { MODAL_TYPES, MODAL_TITLE } from '../constants';
import PatientForm from './PatientForm/PatientForm';

const ModalService = ({ messageApi }) => {
  const { modalStatus, resetModal } = useModalStore((state) => ({
    modalStatus: state.modalStatus,
    updateModalStatus: state.updateModalStatus,
    resetModal: state.resetModal,
  }));

  const { open, type } = modalStatus;
  return (
    <Modal
      title={type in MODAL_TYPES ? `${MODAL_TITLE[type]}` : ''}
      width={800}
      open={open}
      keyboard={false}
      maskClosable={false}
      closeIcon={
        <Popconfirm
          title="Close"
          description="Unsaved changes will be lost. Are you sure you want to close?"
          onConfirm={resetModal}
          okText="Yes"
          cancelText="No"
        >
          <CloseOutlined />
        </Popconfirm>
      }
      destroyOnClose={true}
      footer={null}
    >
      {type === MODAL_TYPES.PATIENT_FORM && (
        <PatientForm messageApi={messageApi} />
      )}
      {/* {type === MODAL_TYPES.LINK_CALL && <div>link call</div>} */}
    </Modal>
  );
};

export default ModalService;
