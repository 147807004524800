export const filterEmptyValues = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== '' &&
      (!Array.isArray(obj[key]) || obj[key].length !== 0)
    ) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};

export const getNonEmptyFilter = (filter) => {
  const requestFilters = {
    dateTo: filter.dateRange[1],
    dateFrom: filter.dateRange[0],
    patientFirstName: filter.patientFirstName,
    patientLastName: filter.patientLastName,
    DOB: filter.DOB,
    sitecode: filter.sitecode,
    pattern: 'PARTIAL',
  };
  const nonEmptyFilters = filterEmptyValues(requestFilters);
  return nonEmptyFilters;
};
