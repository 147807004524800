import React, { useState, useEffect } from 'react';
import { PublicClientApplication, LogLevel } from '@azure/msal-browser';
import { Button } from 'antd';
import PSOPanel from './components/PSOPanel';
import { config } from './config';
import './App.css';

const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: config.appId,
    redirectUri: config.redirectUrl,
    authority: config.authority,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: true,
      loggerCallback: (level, message, containsPii) => {
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
});

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const login = async () => {
    try {
      await publicClientApplication
        .loginPopup({
          scopes: config.scopes,
          prompt: 'select_account',
        })
        .then((res) => {
          if (res.idTokenClaims && 'groups' in res.idTokenClaims) {
            setIsAuthenticated(true);
            setUserInfo({
              username: res?.account?.username,
              tenantId: res?.account?.tenantId,
              name: res?.account?.name,
            });
          } else {
            alert("You don't have access to PSO panel");
          }
        });
    } catch (err) {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    const currentAccounts = publicClientApplication.getAllAccounts();
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('name');
    publicClientApplication.setActiveAccount(currentAccounts[0]);
    if (
      currentAccounts.length > 0 &&
      userId &&
      currentAccounts[0].username.includes(userId)
    ) {
      const silentRequest = {
        scopes: ['user.read'],
        account: currentAccounts[0],
        forceRefresh: false,
      };
      publicClientApplication
        .acquireTokenSilent(silentRequest)
        .then((tokenResponse) => {
          if (
            tokenResponse.idTokenClaims &&
            'groups' in tokenResponse.idTokenClaims
          ) {
            setIsAuthenticated(true);
          } else {
            alert("You don't have access to PSO panel");
          }
        })
        .catch((error) => {
          setIsAuthenticated(true);
        });
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <div>
      {isAuthenticated ? (
        <PSOPanel userInfo={userInfo} />
      ) : (
        <div className="loginContainer">
          <Button type="primary" onClick={login}>
            Login in to MED Dashboard
          </Button>
        </div>
      )}
    </div>
  );
}

export default App;
