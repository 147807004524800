import { useEffect, useState } from 'react';
import _, { startCase } from 'lodash';
import { Form, Input } from 'antd';
import { MANDATORY_STRING, OPTIONAL_STRING } from '../../constants';

const { TextArea } = Input;

const FreeTextField = ({
  item,
  i,
  isReadOnly,
  form,
  handleChange,
  classifiedFields,
  errorMessage,
}) => {
  const [value, setValue] = useState('');
  const maxLength = {
    furtherInformation: 1000,
    presentingConditions: 500,
  };
  const setTextValue = () => {
    let text = '';
    if (
      form[item] &&
      (form[item].toString().includes(OPTIONAL_STRING) ||
        form[item].toString().includes(MANDATORY_STRING))
    ) {
      text = form[item].split('/')[1];
    } else {
      text = form[item] ? form[item] : '';
    }
    setValue(text);
  };

  useEffect(() => {
    setTextValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form[item]]);

  const parseLines = (value) => value.replace(/(\\n)/g, '\n');

  return (
    <Form.Item
      key={`${i}-${item}`}
      label={
        classifiedFields['types']?.[item]?.['fieldLabel'] ?? startCase(item)
      }
      colon={false}
      labelWrap={true}
      help={_.has(errorMessage, item) ? errorMessage[item] : ''}
      validateStatus={_.has(errorMessage, item) ? 'error' : ''}
    >
      {classifiedFields.bigTexts.includes(item) ? (
        <TextArea
          rows={4}
          value={
            classifiedFields.bigTexts.includes(item) && value
              ? parseLines(value)
              : value
          }
          onChange={(e) => handleChange(e, item)}
          disabled={isReadOnly || item === 'furtherInformation' ? true : false}
          // size="small"
          maxLength={
            classifiedFields.bigTexts.includes(item) ? maxLength[item] : 100
          }
        />
      ) : (
        <Input
          value={
            item === 'DOB' &&
            form[item] !== null &&
            form[item] !== MANDATORY_STRING
              ? form[item]
              : classifiedFields.bigTexts.includes(item) && value
                ? parseLines(value)
                : value
          }
          type={classifiedFields['types'][item]?.['uiType'] ?? 'text'}
          onChange={(e) => handleChange(e, item)}
          disabled={isReadOnly}
        />
      )}
    </Form.Item>
  );
};

export default FreeTextField;
