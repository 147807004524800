export const MANDATORY_STRING = 'MANDATORY/';
export const OPTIONAL_STRING = 'YES/';
export const DEFAULT_SELECT_STRING = '-Select-';

export const MODAL_TYPES = {
  PATIENT_FORM: 'PATIENT_FORM',
  LINK_CALL: 'LINK_CALL',
};

export const MODAL_TITLE = {
  PATIENT_FORM: 'Patient Form',
  LINK_CALL: 'Link Call',
};

export const INVALID_COMPARE_TIME_HELPER =
  'Please ensure the start time is earlier than the end time';

export const INVALID_TIME_HELPER = 'Please enter a time that is in the past.';

export const GENERAL_PSO_FORM_FIELDS = ['sitecode', 'callType'];
export const CUSTOM_FIELDS = [
  {
    // fields active status
    status: 'clientFieldOnOff1',
    // label to show in FE
    label: 'clientFieldLabel1',
    // discrete name to save in DB
    discreteName: 'clientDiscreteName1',
    // dropdown options if any
    dropdownValue: 'clientFieldDropdownValue1',
    // field value for example: MANDATORY/123
    nature: 'clientFieldNature1',
    // ui type, for example: text, dropdown, bigText
    type: 'clientFieldUiType1',
  },
  {
    status: 'clientFieldOnOff2',
    label: 'clientFieldLabel2',
    discreteName: 'clientDiscreteName2',
    dropdownValue: 'clientFieldDropdownValue2',
    nature: 'clientFieldNature2',
    type: 'clientFieldUiType2',
  },
  {
    status: 'clientFieldOnOff3',
    label: 'clientFieldLabel3',
    discreteName: 'clientDiscreteName3',
    dropdownValue: 'clientFieldDropdownValue3',
    nature: 'clientFieldNature3',
    type: 'clientFieldUiType3',
  },
  {
    status: 'clientFieldOnOff4',
    label: 'clientFieldLabel4',
    discreteName: 'clientDiscreteName4',
    dropdownValue: 'clientFieldDropdownValue4',
    nature: 'clientFieldNature4',
    type: 'clientFieldUiType4',
  },
  {
    status: 'clientFieldOnOff5',
    label: 'clientFieldLabel5',
    discreteName: 'clientDiscreteName5',
    dropdownValue: 'clientFieldDropdownValue5',
    nature: 'clientFieldNature5',
    type: 'clientFieldUiType5',
  },
];
