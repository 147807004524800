import { useState } from 'react';
import _, { startCase } from 'lodash';
import { AutoComplete, Form } from 'antd';
import {
  // MANDATORY_STRING,
  DEFAULT_SELECT_STRING,
  // OPTIONAL_STRING,
} from '../../constants';

const SitecodeField = ({
  i,
  isReadOnly,
  selectedSitecode,
  setSelectedSitecode,
  handleDropdownChange,
  errorMessage,
  dropdownList,
}) => {
  const [sitecodeText, setSitecodeText] = useState(
    selectedSitecode ?? DEFAULT_SELECT_STRING
  );

  const onTextChange = (data) => {
    setSitecodeText(data);
  };
  return (
    <Form.Item
      key={`${i}-${'sitecode'}`}
      label={startCase('sitecode')}
      colon={false}
      labelWrap={true}
      help={_.has(errorMessage, 'sitecode') ? errorMessage['sitecode'] : ''}
      validateStatus={_.has(errorMessage, 'sitecode') ? 'error' : ''}
    >
      <AutoComplete
        value={sitecodeText}
        options={dropdownList['sitecode'] ?? []}
        disabled={isReadOnly ? true : false}
        onChange={onTextChange}
        filterOption={(inputValue, option) =>
          option.value?.toUpperCase()?.indexOf(inputValue.toUpperCase()) !== -1
        }
        onBlur={() => {
          const sitecodes = dropdownList['sitecode']?.map(
            (option) => option.value
          );
          if (!sitecodes?.includes(sitecodeText)) {
            setSitecodeText(selectedSitecode);
          }
        }}
        onSelect={(data) => {
          handleDropdownChange(data, 'sitecode');
          setSelectedSitecode(data);
          setSitecodeText(data);
        }}
      />
    </Form.Item>
  );
};

export default SitecodeField;
