import { create } from 'zustand';

const defaultModalStatus = {
  open: false,
  type: null,
};

const useModalStore = create((set) => ({
  modalStatus: defaultModalStatus,
  updateModalStatus: (modelState) => {
    set((state) => ({ modalStatus: { ...state.modalStatus, ...modelState } }));
  },
  resetModal: () =>
    set({
      modalStatus: defaultModalStatus,
    }),
}));

export default useModalStore;
