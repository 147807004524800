/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { has, startCase } from 'lodash';
import { Select, Form } from 'antd';
import {
  MANDATORY_STRING,
  DEFAULT_SELECT_STRING,
  OPTIONAL_STRING,
} from '../../constants';

const DropdownField = ({
  item,
  i,
  isReadOnly,
  form,
  handleDropdownChange,
  errorMessage,
  dropdownList,
}) => {
  const [value, setValue] = useState(DEFAULT_SELECT_STRING);
  useEffect(() => {
    setDropdownValue();
  }, [form[item]]);

  const setDropdownValue = () => {
    let text = DEFAULT_SELECT_STRING;
    if (
      form[item] &&
      (form[item].toString().includes(OPTIONAL_STRING) ||
        form[item].toString().includes(MANDATORY_STRING))
    ) {
      const defaultValue = form[item].split('/')[1];
      text = defaultValue ? defaultValue : DEFAULT_SELECT_STRING;
    } else {
      text = form[item] ? form[item] : DEFAULT_SELECT_STRING;
    }
    setValue(text);
  };
  return (
    <Form.Item
      key={`${i}-${item}`}
      label={startCase(item)}
      colon={false}
      labelWrap={true}
      help={has(errorMessage, item) ? errorMessage[item] : ''}
      validateStatus={has(errorMessage, item) ? 'error' : ''}
    >
      <Select
        key={`${item}-${i}`}
        value={value}
        options={
          Array.isArray(dropdownList[item]) && dropdownList[item]?.length > 0
            ? dropdownList[item].map((option) => ({
                label: option,
                value: option,
              }))
            : []
        }
        onChange={(value) => handleDropdownChange(value, item)}
        disabled={isReadOnly ? true : false}
      />
    </Form.Item>
  );
};

export default DropdownField;
